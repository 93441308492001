.ant-picker {
  border-radius: 6px;
  height: 24px;
}
.ant-picker-input > input {
  text-align: center;
}
.ant-picker-range-separator {
  padding: 0;
}
.ant-picker {
  padding: 4px 8px 4px 4px;
}