@tailwind base;
@tailwind components;
@tailwind utilities;

html, button, .chart-container {
  font-family: 'Inter', sans-serif !important;
  font-style: 'italic' !important;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11" !important;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  font-style: 'italic' !important;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

