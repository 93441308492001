.btn-outline {
  border-color: #9999996e;
  background-color: transparent;

}
.btn-outLine:hover,
.btn-outline.btn:hover,
.btn-outline.btn active {
  --tw-border-opacity: 1;
  border-color: #9999996e;
  -tw-bg-opacity: 1;
  background-color: #f3f3f3;
  --tw-text-opacity: 1;
  color: #333333;
}