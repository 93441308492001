.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-header {
  border-bottom: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 0px;
}

.ant-modal-footer {
  border-top: 0px;
  height: 64px;
}